import { createRouter, createWebHistory } from 'vue-router'
import { trackRouter } from 'vue-gtag-next'

const routes = [
  {
    path: '/',
    name:'首頁',
    component: () => import('../Views/Index.vue'),
    alias: '/home'
  },
  // ? 內頁
  {
    name:'信用卡',
    redirect:'/',
    path: '/',
    component: () => import('../Template/DescriptionTemplate.vue'),
    meta: {
      breadCrumb: [{
        title: '聯邦信用卡',
        link: '/'
      }]
    },
    children: [
      {
        path: 'Card',
        name: '信用卡家族',
        component: () => import('../Views/011/Cards.vue'),
        meta: {
          leftMenu: ["01", "011"],
          breadCrumb: [{
            title: '卡片總覽'
          },]
        }
      },
      {
        path: 'CardDetail/:fileName',
        name: '信用卡介紹',
        component: () => import('../Views/011/CardDetail.vue'),
        meta: {
          leftMenu: ["01", "011"],
          breadCrumb: [{
            title: '卡片總覽'
          },]
        }
      },
      {
        path: 'CardRights/:fileName',
        name: '卡友權益',
        component: () => import('../Views/012/CardRights.vue'),
        meta: {
          leftMenu: ["01", "012"],
          breadCrumb: [{
            title: '產品介紹及權益'
          },{
            title: '卡友權益'
          }]
        }
      },
      {
        path: 'CardTravel/:fileName',
        name: '國旅卡專區',
        component: () => import('../Views/014/CardTravel.vue'),
        meta: {
          leftMenu: ["01", "014"],
          breadCrumb: [{
            title: '產品介紹及權益'
          },{
            title: '國旅卡專區'
          }]
        }
      },
      {
        path: 'AnnounceDescription/:fileName',
        name: '公告與說明',
        component: () => import('../Views/015/AnnounceDescription.vue'),
        meta: {
          leftMenu: ["01", "015"],
          breadCrumb: [{
            title: '產品介紹及權益'
          },{
            title: '公告與說明'
          }]
        }
      },
      {
        path: 'CardActivity',
        name: '刷卡優惠活動',
        component: () => import('../Views/021/CardActivity.vue'),
        meta: {
          leftMenu: ["02", "021"],
          breadCrumb: [{
            title: '刷卡優惠'
          },{
            title: '刷卡優惠活動'
          }]
        }
      },
      {
        path: 'RewardProgram/feedback',
        name: '紅利回饋辦法',
        component: () => import('../Views/041/RewardProgram.vue'),
        meta: {
          leftMenu: ["04", "041"],
          breadCrumb: [{
            title: '客戶回饋計畫'
          },{
            title: '紅利回饋辦法'
          }]
        }
      },
      {
        path: 'RewardProgram/reward_a1',
        name: '商品兌換',
        component: () => import('../Views/043/RedeemProducts.vue'),
        meta: {
          leftMenu: ["04", "043"],
          breadCrumb: [{
            title: '客戶回饋計畫'
          },{
            title: '商品兌換'
          }]
        },
        alias: []
      },
      {
        path: 'RewardProgram/air',
        name: '航空酬賓',
        component: () => import('../Views/044/RewardAviation.vue'),
        meta: {
          leftMenu: ["04", "044"],
          breadCrumb: [{
            title: '客戶回饋計畫'
          },{
            title: '航空酬賓'
          }]
        },
        alias: ['RewardProgram/reward_a2']
      },
      {
        path: 'RewardProgram/Loylty',
        name: '其他兌換',
        component: () => import('../Views/046/Exchange.vue'),
        meta: {
          leftMenu: ["04", "046"],
          breadCrumb: [{
            title: '客戶回饋計畫'
          },{
            title: '其他兌換'
          }]
        }
      },
      {
        path: 'DigitalService/:fileName',
        name: '數位服務',
        component: () => import('../Views/053/DigitalService.vue'),
        meta: {
          leftMenu: ["05", "053"],
          breadCrumb: [{
            title: '客戶回饋計畫'
          },{
            title: '數位服務'
          }]
        }
      },
      {
        path: 'Payment/:fileName',
        name: '帳務服務',
        component: () => import('../Views/054/AccountService.vue'),
        meta: {
          leftMenu: ["05", "054"],
          breadCrumb: [{
            title: '卡友服務'
          },{
            title: '帳務服務'
          }]
        }
      },
      {
        path: 'OtherService/:fileName',
        name: '其他服務',
        component: () => import('../Views/055/OtherService.vue'),
        meta: {
          leftMenu: ["05", "055"],
          breadCrumb: [{
            title: '卡友服務'
          },{
            title: '其他服務'
          }]
        }
      },
      {
        path: 'StoreAcquire/:fileName',
        name: '實體商店收單',
        component: () => import('../Views/071/StoreReceipt.vue'),
        meta: {
          leftMenu: ["07", "071"],
          breadCrumb: [{
            title: '收單業務'
          },{
            title: '實體商店收單'
          }]
        }
      },
      {
        path: 'WebAcquire/:fileName',
        name: '網路收單服務',
        component: () => import('../Views/072/WebAcquire.vue'),
        meta: {
          leftMenu: ["07", "072"],
          breadCrumb: [{
            title: '收單業務'
          },{
            title: '網路收單服務'
          }]
        }
      },
      {
        path: 'ubcard/:fileName',
        name: '表尾附加頁面',
        component: () => import('../Views/ubcard/AdditionalPage.vue'),
        meta: {
          leftMenu: [],
          breadCrumb: []
        }
      }
    ]
  },
  {
    path:'/MapGuide/Map',
    name:'網站導覽',
    component: () => import('../Views/MapGuide.vue')
  }
  /* {
    // ?未對應的路由重新導向到首頁
    path: '/:pathMatch(.*)*',
    redirect: {
      name: '首頁'
    }
  } */
]

const routeCfg = {
  history: createWebHistory(process.env.VUE_APP_BASE_URL),
  scrollBehavior() {
    return {
      top: 0
    }
  }
}

routeCfg.routes = routes

const router = createRouter(routeCfg)

trackRouter(router)

export default router

