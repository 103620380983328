import { hash } from './hash'

export async function cssImport(urls){
  const arr = []
  for (let i = 0; i < urls.length; i++) {
    const result = new Promise((resolve,reject)=>{
      const cssLink = document.createElement('link')
      cssLink.setAttribute('rel', 'stylesheet')
      cssLink.setAttribute('type', 'text/css')
      cssLink.setAttribute('href', `${urls[i]}?${hash(8)}`)
      document.head.appendChild(cssLink)
      cssLink.onload = () => {
        resolve(true)
      }
    })
    arr.push(result)
  }
  await Promise.all(arr)
}
