function loadJs(src) {
  return new Promise((resolve, reject) => {
    let script = document.createElement("script");
    script.type = "text/javascript";

    let path = window.location.protocol + '//' + window.location.hostname;
    if (window.location.port != "80" && !window.location.port != "443") {
      path += ':' + window.location.port;
    }
    if (process.env.VUE_APP_BASE_URL != "/") {
      path += process.env.VUE_APP_BASE_URL;
    }
    script.src = path + '/' + src;

    document.getElementById("ja").appendChild(script);

    script.onload = () => {
      resolve();
    };
    script.onerror = () => {
      reject();
    };

  });
}

export default loadJs;

