import { hash } from './hash'

export async function jsImport(urls){
  const arr = []
  for (let i = 0; i < urls.length; i++) {
    const result = new Promise((resolve)=>{
      const jsScript = document.createElement('script')
      jsScript.setAttribute('src', `${urls[i]}?${hash(8)}`)
      document.head.appendChild(jsScript)
      jsScript.onload = () => {
        resolve(true)
      }
    })
    arr.push(result)
  }
  await Promise.all(arr)
}