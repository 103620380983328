import { createApp } from 'vue'
import router from './router/router.js'
import jQuery from "../public/js/jquery.min.js"
import axios from 'axios'
import VueAxios from 'vue-axios'

// ?vue-gtag-next
import VueGtag from 'vue-gtag-next'

// import "../public/css/animate.min.css"

import store from './store'
import { hash } from './Service/hash.js'

import "../public/js/bootstrap.bundle.min.js"
import "../public/sw/swiper-bundle.min.js"


import "../public/css/bootstrap.min.css"
import "../public/sw/swiper-bundle.min.css"
import "../public/css/fontawesome.css"
import { cssImport } from './Service/cssImport'


import App from './App.vue'

window.$ = window.jQuery = jQuery


const start = async () => {
  const urls = [
    `${process.env.VUE_APP_STATIC}/css/style_B5.css`,
    '../css/menu.css',
    '../css/index.css',
    '../css/cards.css'
  ]
  await cssImport(urls)

  const app = createApp(App)
  app.config.globalProperties.$custom = {
    hash
  }// ?自訂全域屬性

  app.use(router)
  app.use(store)
  app.use(VueAxios, axios)
  // ? GA 追蹤碼設定
  if (window.location.href.includes('card.ubot.com.tw')) {
    app.use(VueGtag, {
      property: [
        { id: 'UA-61958730-2' }
      ]
    })
  }

  app.mount('#app')
}


start()