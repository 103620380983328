import { createStore } from 'vuex'

export default createStore({
  state:{
    breadCrumb:[],
    isLoading:false
  },
  mutations:{
    updateBreadCrumb(state,payload){
      state.breadCrumb = payload
    },
    changeLoading(state,payload){
      state.isLoading = payload
    }
  },
  actions:{}
})