<template>
  <div id="ja"></div>
  <div class="position-fixed w-100 h-100 d-flex justify-content-center align-items-center" style="background:rgba(60,60,60,0.8);z-index:1000" v-if="$store.state.isLoading">
    <img src="../public/ajaxDog.gif" alt="">
  </div>
  <router-view></router-view>
  <GoTop></GoTop>
</template>

<script>
import GoTop from "./components/GoTop.vue"
import { jsImport } from '@/Service/jsImport'

export default {
  components: {
    GoTop
  },
  async created() {
    const envJsUrls = [
      `${process.env.VUE_APP_PATH}/frontEnd_source/js/ENV.js`,
    ]
    await jsImport(envJsUrls)
    const jsUrls = [
      `${process.env.VUE_APP_PATH}/frontEnd_source/js/components.js`
    ]
    await jsImport(jsUrls)
  }
};
</script>
