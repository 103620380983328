<template>
  <div>
    <button onclick="topFunction()" id="goToTopBtn" title="Go to top">▲ Top</button>
  </div>
</template>

<script>
import loadJs from "@/Service/JSExport";
export default {
  mounted() {
    loadJs("jsUB/backToTop.js");
  }
}
</script>

<style>
#goToTopBtn {
  display: none;
  position: fixed;
  bottom: 20px;
  right: 30px;
  z-index: 99;
  font-size: 15px;
  border: none;
  outline: none;
  background-color: #CCCCCC;
  color: white;
  cursor: pointer;
  padding: 10px;
  border-radius: 4px;
}

@media all and (max-width: 992px) {
  #goToTopBtn {
    background-color: #1BBBBB;
  }
}

@media all and (min-width: 993px) {
  #goToTopBtn:hover {
    background-color: #1BBBBB;
  }
}
</style>